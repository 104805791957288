<template>
  <div>
    <b-sidebar
      id="sidebar-add-new-event"
      sidebar-class="sidebar-lg"
      :visible="isEventHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-event-handler-sidebar-active', val)"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ eventLocal.id ? 'Update': 'Create' }} Booking
          </h5>
          <div>
            <feather-icon
              v-if="eventLocal.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-event'); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="calendar-booking-form"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <div
              class="px-2"
            >
              <!-- Calendar -->
              <validation-provider
                #default="validationContext"
                name="Activity Type"
                rules="required"
              >

                <b-form-group
                  label="Activity Type"
                  label-for="activityType"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="eventLocal.activityType"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="calendarOptions"
                    label="typeName"
                    :reduce="calendar => calendar._id"
                    input-id="activityType"
                    :clearable="false"
                    @input="updatedActivityType"
                  >

                    <template #option="{ color, typeName }">
                      <div
                        class="rounded-circle rounded-circle-activity d-inline-block mr-50"
                        style="height:10px;width:10px"
                        :style="{ '--bg-color': color }"
                      />
                      <span> {{ typeName }}</span>
                    </template>

                    <template #selected-option="{ color, typeName }">
                      <div
                        class="rounded-circle rounded-circle-activity d-inline-block mr-50"
                        style="height:10px;width:10px"
                        :style="{ '--bg-color': color }"
                      />
                      <span> {{ typeName }}</span>
                    </template>
                  </v-select>

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
            <hr class="dividerHR">
            <div
              class="px-2"
            >
              <!-- Start Date -->
              <!-- <validation-provider
                #default="validationContext"
                name="Start Date"
                rules="required"
              >

                <b-form-group
                  label="Start Date"
                  label-for="start-date"
                  :state="getValidationState(validationContext)"
                >
                  <flat-pickr
                    v-model="eventLocal.startDate"
                    class="form-control"
                    :config="{ dateFormat: 'd/m/Y', disableMobile: true }"
                    @on-change="getTimeFromDB"
                  />
                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <small
                    v-if="dateInvalidFeedback"
                    class="text-danger"
                  >
                    {{ dateInvalidMessage }}
                  </small>
                </b-form-group>
              </validation-provider> -->

              <b-form-group
                label="Start Date"
                label-for="start-date"
              >
                <b-input-group>
                  <flat-pickr
                    v-model="eventLocal.startDate"
                    placeholder="dd/mm/yyyy"
                    class="form-control flat-pickr-group"
                    :config="{ wrap: true, dateFormat: 'd/m/Y', minDate: 'today', disableMobile: true }"
                    @on-change="getTimeFromDB"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      icon="CalendarIcon"
                      class="cursor-pointer"
                      data-toggle
                      size="18"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small
                  v-if="dateInvalidFeedback"
                  class="text-danger"
                >
                  {{ dateInvalidMessage }}
                </small>
              </b-form-group>

              <validation-provider
                #default="validationContext"
                name="Start Time"
                rules="required"
              >
                <b-form-group
                  label=""
                  label-for="startTime"
                >
                  <b-form-radio-group
                    id="startTime"
                    v-model="eventLocal.startTime"
                    buttons
                    button-variant="outline-primary"
                    class="spaceBetweenRadio timeSlotButtons"
                    :state="getValidationState(validationContext)"
                  >
                    <b-form-radio
                      v-for="(option, index) in hoursInterval"
                      :key="index"
                      :value="option.timeValue"
                      class="mb-50"
                      :disabled="option.status !== 'enabled'"
                      @change="checkForCustomerSchedule"
                    >
                      {{ option.time }} <span class="bookingCounter">{{ option.count }}</span>
                    </b-form-radio>
                  </b-form-radio-group>

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <small
                    v-if="timePassedError"
                    class="text-danger"
                  >
                    {{ timePassedErrorMessage }}
                  </small>
                  <div
                    v-if="scheduleValidation"
                    class="booking_time_error"
                  >
                    <p>This customer has another booking in a similar timeslot.</p>
                    <p>
                      <router-link
                        :to="{ name: 'appointment-bookings-show', params: { id: existedBooking._id || 0 } }"
                      >
                        <strong>Booking {{ existedBooking.stringID || 0 }}</strong>
                      </router-link>
                      <router-link
                        :to="{ name: 'appointment-bookings-show', params: { id: existedBooking._id || 0 } }"
                      >
                        <feather-icon
                          icon="ExternalLinkIcon"
                          class="mr-50"
                          size="16"
                        />
                      </router-link>
                      <br>{{ existedBooking.activityType.typeName || '' }} • Confirmed<br>{{ existedBooking.customerName || '' }} • <span v-if="existedBooking.customerContact">(65) {{ existedBooking.customerContact || '' }}</span><span v-else>{{ existedBooking.customerEmail || '' }}</span>
                    </p>
                    <p><strong>Agent:</strong> <br>{{ existedBooking.agentName || '' }} • {{ existedBooking.agentCode || '' }}</p>
                  </div>
                </b-form-group>
              </validation-provider>
            </div>
            <hr class="dividerHR">

            <div
              class="px-2"
            >
              <!-- Title -->
              <validation-provider
                #default="validationContext"
                name="Customer Name"
                rules="required"
              >
                <b-form-group
                  label="Customer Name*"
                  label-for="event-customer-name"
                >
                  <b-form-input
                    id="event-customer-name"
                    v-model="eventLocal.customerName"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Customer Name"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <div v-if="!customerEmail">
                <validation-provider
                  #default="validationContext"
                  name="Customer Contact"
                  rules="required|digits:8"
                >
                  <b-form-group
                    label="Customer Contact*"
                    label-for="event-customer-contact"
                  >
                    <b-form-input
                      id="event-customer-contact"
                      v-model="eventLocal.customerContact"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="Customer Contact"
                      @input="checkForCustomerSchedule"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <p
                  class="addOptionText"
                  @click="customerEmail = true; eventLocal.customerContact = ''"
                >
                  Use Email Instead
                </p>
              </div>

              <div v-if="customerEmail">
                <validation-provider
                  #default="validationContext"
                  name="Customer Email"
                  rules="required|email"
                >
                  <b-form-group
                    label="Customer Email*"
                    label-for="event-customer-email"
                  >
                    <b-form-input
                      id="event-customer-email"
                      v-model="eventLocal.customerEmail"
                      :state="getValidationState(validationContext)"
                      trim
                      placeholder="Customer Email"
                      @input="checkForCustomerSchedule"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <p
                  class="addOptionText"
                  @click="customerEmail = false; eventLocal.customerEmail = ''"
                >
                  Use Mobile Number Instead
                </p>
              </div>
            </div>
            <hr
              v-if="customFields.length"
              class="dividerHR"
            >
            <div
              class="px-2"
            >
              <div v-if="customFields.length && customFields.length === eventLocal.customFields.length">
                <div
                  v-for="(opt, key) in customFields"
                  :key="key"
                >
                  <validation-provider
                    v-if="opt.type == 'short-answer'"
                    #default="validationContext"
                    :name="opt.label"
                    :rules="opt.required == 'yes' ? 'required' : ''"
                  >
                    <b-form-group
                      :label="opt.label"
                      :label-for="'event-custom-field-' + key"
                    >
                      <b-form-input
                        :id="'event-custom-field-' + key"
                        v-model="eventLocal.customFields[key].value"
                        :state="getValidationState(validationContext)"
                        trim
                        :placeholder="opt.label"
                        @input="generateBookingTitle"
                      />

                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    v-if="opt.type == 'long-answer'"
                    #default="validationContext"
                    :name="opt.label"
                    :rules="opt.required == 'yes' ? 'required' : ''"
                  >
                    <b-form-group
                      :label="opt.label"
                      :label-for="'event-custom-field-' + key"
                    >
                      <b-form-textarea
                        :id="'event-custom-field-' + key"
                        v-model="eventLocal.customFields[key].value"
                        :state="getValidationState(validationContext)"
                        trim
                        :placeholder="opt.label"
                        @input="generateBookingTitle"
                      />

                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    v-if="opt.type == 'multi-select' && opt.multiSelectType == 'multiple'"
                    #default="validationContext"
                    :name="opt.label"
                    :rules="opt.required == 'yes' ? 'required' : ''"
                  >
                    <b-form-group
                      :label="opt.label"
                      :label-for="'event-custom-field-' + key"
                    >
                      <b-form-checkbox-group
                        :id="'event-custom-field-' + key"
                        v-model="eventLocal.customFields[key].value"
                        buttons
                        button-variant="outline-primary"
                        stacked
                        class="spaceBetweenRadio"
                        @input="generateBookingTitle"
                      >
                        <b-form-checkbox
                          v-for="(option, kindex) in opt.extraOptions"
                          :key="kindex"
                          :value="option.key"
                          class="mb-50 customCheckbox"
                        >
                          <feather-icon
                            v-if="eventLocal.customFields[key].value.includes(option.key)"
                            icon="CheckIcon"
                            class="mr-50"
                            size="18"
                          />
                          <span class="align-middle">{{ option.key }}</span>
                        </b-form-checkbox>
                      </b-form-checkbox-group>

                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    v-if="opt.type == 'multi-select' && opt.multiSelectType == 'single'"
                    #default="validationContext"
                    :name="opt.label"
                    :rules="opt.required == 'yes' ? 'required' : ''"
                  >
                    <b-form-group
                      :label="opt.label"
                      :label-for="'event-custom-field-' + key"
                    >
                      <!-- <b-form-radio-group
                        :id="'event-custom-field-' + key"
                        v-model="eventLocal.customFields[key].value"
                        button-variant="outline-primary"
                        buttons
                        stacked
                        class="spaceBetweenRadio"
                        @input="generateBookingTitle"
                      >
                        <b-form-radio
                          v-for="(option, kindex) in opt.extraOptions"
                          :key="kindex"
                          :value="option.key"
                          class="mb-50"
                        >
                          <feather-icon
                            v-if="eventLocal.customFields[key].value.includes(option.key)"
                            icon="CheckIcon"
                            class="mr-50"
                            size="18"
                          />
                          <span class="align-middle">{{ option.key }}</span>
                        </b-form-radio>
                      </b-form-radio-group> -->

                      <b-form-checkbox-group
                        :id="'event-custom-field-' + key"
                        v-model="eventLocal.customFields[key].value"
                        buttons
                        stacked
                        button-variant="outline-primary"
                        class="spaceBetweenRadio display-block-checkbox"
                        @input="makeCheckboxToRadio(key)"
                      >
                        <b-form-checkbox
                          v-for="(option, kindex) in opt.extraOptions"
                          :key="kindex"
                          :value="option.key"
                          class="mb-50 customCheckbox"
                        >
                          <feather-icon
                            v-if="eventLocal.customFields[key].value.includes(option.key)"
                            icon="CheckIcon"
                            class="mr-50"
                            size="16"
                          />
                          <span class="align-middle">{{ option.key }}</span>
                        </b-form-checkbox>
                      </b-form-checkbox-group>

                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>

                  <validation-provider
                    v-if="opt.type == 'single-select'"
                    #default="validationContext"
                    :name="opt.label"
                    :rules="opt.required == 'yes' ? 'required' : ''"
                  >
                    <b-form-group
                      :label="opt.label"
                      :label-for="'event-custom-field-' + key"
                    >
                      <!-- <b-form-radio-group
                        :id="'event-custom-field-' + key"
                        v-model="eventLocal.customFields[key].value"
                        button-variant="outline-primary"
                        buttons
                        class="spaceBetweenRadio"
                        @input="generateBookingTitle"
                      >
                        <b-form-radio
                          v-for="(option, kindex) in requiredOptions"
                          :key="kindex"
                          :value="option.value"
                        >
                          <feather-icon
                            v-if="eventLocal.customFields[key].value === option.value"
                            icon="CheckIcon"
                            class="mr-50"
                            size="16"
                          />
                          <span class="align-middle">{{ option.text }}</span>
                        </b-form-radio>
                      </b-form-radio-group> -->

                      <b-form-checkbox-group
                        :id="'event-custom-field-' + key"
                        v-model="eventLocal.customFields[key].value"
                        buttons
                        button-variant="outline-primary"
                        class="spaceBetweenRadio display-block-checkbox"
                        @input="makeCheckboxToRadio(key)"
                      >
                        <b-form-checkbox
                          v-for="(option, kindex) in requiredOptions"
                          :key="kindex"
                          :value="option.value"
                          class="mb-50 customCheckbox"
                        >
                          <feather-icon
                            v-if="eventLocal.customFields[key].value.includes(option.value)"
                            icon="CheckIcon"
                            class="mr-50"
                            size="16"
                          />
                          <span class="align-middle">{{ option.text }}</span>
                        </b-form-checkbox>
                      </b-form-checkbox-group>

                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </div>
              </div>
            </div>
            <hr class="dividerHR">
            <div
              class="px-2"
            >
              <p
                v-if="!showRemarksField"
                class="addOptionText"
                @click="showRemarksField = true"
              >
                Add Remarks
              </p>
              <b-form-group
                v-if="showRemarksField"
                label="Remarks"
                label-for="event-remarks"
              >
                <b-form-textarea
                  id="event-remarks"
                  v-model="eventLocal.remarks"
                  placeholder="Remarks"
                  rows="3"
                  name="remarks"
                />
              </b-form-group>
            </div>
            <hr class="dividerHR">
            <div
              class="px-2"
            >
              <validation-provider
                #default="validationContext"
                name="Title"
                rules="required"
              >
                <b-form-group
                  label="Title"
                  label-for="event-title"
                >
                  <b-form-input
                    id="event-title"
                    v-model="eventLocal.title"
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Title"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
            <hr class="dividerHR">
            <div
              class="px-2"
            >
              <validation-provider
                #default="validationContext"
                name="Duty Officer"
                rules="required"
              >

                <b-form-group
                  label="Duty Officer"
                  label-for="dutyOfficer"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="eventLocal.dutyOfficer"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="dutyOfficerOptions"
                    label="name"
                    :reduce="name => name._id"
                    input-id="dutyOfficer"
                    :clearable="true"
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="validationContext"
                name="Status"
                rules="required"
              >

                <b-form-group
                  label="Status*"
                  label-for="status"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="eventLocal.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="statusOptions"
                    label="title"
                    :reduce="title => title.code"
                    input-id="status"
                    :clearable="false"
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider
                #default="validationContext"
                name="Source of Booking"
                rules="required"
              >

                <b-form-group
                  label="Source of Booking*"
                  label-for="source-of-booking"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="eventLocal.sourceOfBooking"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="sourceOfBookingOptions"
                    label="title"
                    :reduce="title => title.code"
                    input-id="source-of-booking"
                    :clearable="false"
                  />

                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </div>
            <hr class="dividerHR">
            <div
              class="px-2"
            >
              <p
                v-if="!showCallLogFields"
                class="addOptionText"
                @click="showCallLogFields = true"
              >
                Add Call Log
              </p>
              <div v-if="showCallLogFields">
                <validation-provider
                  #default="validationContext"
                  name="Type of Call"
                  rules=""
                >

                  <b-form-group
                    label="Type of Call"
                    label-for="type-of-call"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="eventLocal.typeOfCall"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="name"
                      :options="callLogTypes"
                      :reduce="name => name._id"
                      :clearable="false"
                      @input="setNatureOfCalls"
                    />

                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  #default="validationContext"
                  name="Nature of Call"
                  rules=""
                >

                  <b-form-group
                    label="Nature of Call"
                    label-for="nature-of-call"
                    :state="getValidationState(validationContext)"
                  >
                    <v-select
                      v-model="eventLocal.natureOfCall"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      label="title"
                      :options="callNatures"
                      :reduce="title => title.code"
                      :clearable="false"
                    />

                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>

                <b-form-group
                  label="Details and Remarks"
                  label-for="call-log-details"
                >
                  <b-form-textarea
                    id="call-log-details"
                    v-model="eventLocal.callLogDescription"
                    placeholder="Write a remark..."
                    rows="3"
                    name="callLogDescription"
                  />
                </b-form-group>
              </div>
            </div>
            <hr class="dividerHR">
            <div
              class="px-2"
            >
              <p
                v-if="!showDescriptionField"
                class="addOptionText"
                @click="showDescriptionField = true"
              >
                Add Description
              </p>
              <b-form-group
                v-if="showDescriptionField"
                label="Description"
                label-for="event-description"
              >
                <b-form-textarea
                  id="event-description"
                  v-model="eventLocal.description"
                  placeholder="Description"
                  rows="3"
                  name="description"
                />
              </b-form-group>
            </div>
            <hr class="dividerHR">
            <div
              class="px-2"
            >
              <p
                v-if="!showpaymentRemarksField"
                class="addOptionText"
                @click="showpaymentRemarksField = true"
              >
                Add Payment Remarks
              </p>
              <b-form-group
                v-if="showpaymentRemarksField"
                label="Payment Remarks"
                label-for="event-paymentRemarks"
              >
                <b-form-textarea
                  id="event-paymentRemarks"
                  v-model="eventLocal.paymentRemarks"
                  placeholder="paymentRemarks"
                  rows="3"
                  name="paymentRemarks"
                />
              </b-form-group>
            </div>
            <hr class="dividerHR">
            <div
              class="px-2"
            >
              <p
                v-if="!showAgentFields"
                class="addOptionText"
                @click="showAgentFields = true"
              >
                Add Agent Details
              </p>
              <div v-if="showAgentFields">
                <b-form-group
                  label="Agent Name"
                  label-for="agentName"
                >
                  <b-form-input
                    id="agentName"
                    v-model="eventLocal.agentName"
                    placeholder="Agent Name"
                    name="agentName"
                  />
                </b-form-group>

                <b-form-group
                  label="Agent Code"
                  label-for="agentCode"
                >
                  <b-form-input
                    id="agentCode"
                    v-model="eventLocal.agentCode"
                    placeholder="Agent Code"
                    name="agentCode"
                  />
                </b-form-group>

                <validation-provider
                  #default="validationContext"
                  name="Agent Contact"
                  rules="digits:8"
                >

                  <b-form-group
                    label="Agent Contact"
                    label-for="agentContact"
                    :state="getValidationState(validationContext)"
                  >
                    <b-form-input
                      id="agentContact"
                      v-model="eventLocal.agentContact"
                      placeholder="Agent Contact"
                      name="agentContact"
                    />

                    <b-form-invalid-feedback :state="getValidationState(validationContext)">
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </div>
            </div>
            <hr class="dividerHR">

            <!-- Form Actions -->
            <div class="d-flex ml-auto action__block">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="flat-primary"
                @click="hide"
              >
                Cancel
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mr-2"
                type="submit"
              >
                Add Booking
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormTextarea, BFormCheckboxGroup, BFormCheckbox,
  BFormRadioGroup, BFormRadio, BFormInvalidFeedback, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// eslint-disable-next-line import/named
import {
  required, email, url, min, digits,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref, toRefs } from '@vue/composition-api'
import axios from '@axios'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import useCalendarEventHandler from './useCalendarEventHandler'

export default {
  components: {
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormRadio,
    BFormTextarea,
    BFormCheckboxGroup,
    BFormRadioGroup,
    BInputGroup,
    BInputGroupAppend,

    vSelect,
    flatPickr,
    ValidationProvider,
    BFormInvalidFeedback,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEventHandlerSidebarActive',
    event: 'update:is-event-handler-sidebar-active',
  },
  props: {
    isEventHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    event: {
      type: Object,
      required: true,
    },
    customFields: {
      type: Array,
      required: true,
    },
    clearEventData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      dutyOfficerOptions: [],
      callLogTypes: [],
      callNatures: [],
      customerEmail: false,
      required,
      email,
      url,
      min,
      digits,
      requiredOptions: [
        { text: 'Yes', value: 'yes' },
        { text: 'No', value: 'no' },
      ],
    }
  },

  beforeMount() {
    this.$http.get('appointment/bookings/respond-with/duty-officers')
      .then(response => {
        this.dutyOfficerOptions = response.data.dutyOfficers ?? []
        this.callLogTypes = response.data.callLogTypes ?? []
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  setup(props, { emit }) {
    /*
     ? This is handled quite differently in SFC due to deadlock of `useFormValidation` and this composition function.
     ? If we don't handle it the way it is being handled then either of two composition function used by this SFC get undefined as one of it's argument.
     * The Trick:

     * We created reactive property `clearFormData` and set to null so we can get `resetEventLocal` from `useCalendarEventHandler` composition function.
     * Once we get `resetEventLocal` function which is required by `useFormValidation` we will pass it to `useFormValidation` and in return we will get `clearForm` function which shall be original value of `clearFormData`.
     * Later we just assign `clearForm` to `clearFormData` and can resolve the deadlock. 😎

     ? Behind The Scene
     ? When we passed it to `useCalendarEventHandler` for first time it will be null but right after it we are getting correct value (which is `clearForm`) and assigning that correct value.
     ? As `clearFormData` is reactive it is being changed from `null` to corrent value and thanks to reactivity it is also update in `useCalendarEventHandler` composition function and it is getting correct value in second time and can work w/o any issues.
    */
    const clearFormData = ref(null)
    const dateInvalidFeedback = ref(false)
    const dateInvalidMessage = ref('')
    const hoursInterval = ref([])
    const existedBooking = ref({})
    const timePassedErrorMessage = ref('This time has already passed')
    const timePassedError = ref(false)

    const {
      eventLocal,
      resetEventLocal,
      calendarOptions,

      // UI
      onSubmit,
      guestsOptions,
    } = useCalendarEventHandler(toRefs(props), clearFormData, emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetEventLocal, props.clearEventData)

    clearFormData.value = clearForm

    const statusOptions = ref([
      { title: 'Confirmed', code: 'confirmed' },
      { title: 'Draft', code: 'draft' },
      { title: 'Pending Payment', code: 'pending-payment' },
      { title: 'Pending Approval', code: 'pending-approval' },
      { title: 'Waiting List', code: 'waiting-list' },
    ])

    const sourceOfBookingOptions = ref([
      { title: 'Call', code: 'call' },
      { title: 'Message', code: 'message' },
      { title: 'Walk-in', code: 'walk-in' },
      { title: 'Self-service', code: 'self-service' },
    ])

    const natureOfCallOptions = ref([
      { title: 'Enquiry', code: 'enquiry' },
      { title: 'New Booking', code: 'new booking' },
    ])

    const typeOfCallOptions = ref([
      { title: 'General Enquiry', code: 'general enquiry' },
      { title: 'Booking', code: 'booking' },
    ])

    const showDescriptionField = ref(false)
    const showpaymentRemarksField = ref(false)
    const showRemarksField = ref(false)
    const showCallLogFields = ref(false)
    const showAgentFields = ref(false)
    const scheduleValidation = ref(false)

    function doestimePassed() {
      if (eventLocal.value.startTime && eventLocal.value.startDate) {
        const concatDate = `${eventLocal.value.startDate} ${eventLocal.value.startTime}`
        const momentTime = moment(concatDate, 'DD/MM/YYYY, HH:mm')
        const currentTime = moment()
        if (momentTime.isSameOrBefore(currentTime)) {
          timePassedError.value = true
        } else {
          timePassedError.value = false
        }
      }
    }

    function getTimeFromDB() {
      doestimePassed()
      if (eventLocal.value.startDate && eventLocal.value.activityType) {
        axios
          .post('appointment/bookings/date-and-time-availability', { date: eventLocal.value.startDate, activity: eventLocal.value.activityType })
          .then(response => {
            hoursInterval.value = response.data.hours
            eventLocal.value.timeSlotDuration = response.data.timeSlotDuration
            dateInvalidFeedback.value = false
          })
          .catch(error => {
            hoursInterval.value = []
            dateInvalidFeedback.value = true
            dateInvalidMessage.value = error.data.message
          })
      }
    }

    function generateBookingTitle() {
      let title = ''
      props.customFields.forEach((arrayItem, key) => {
        if (arrayItem.displayInCalendar === 'yes' && eventLocal.value.customFields[key].value !== '') {
          const val = eventLocal.value.customFields[key].value
          if (Array.isArray(val)) {
            val.forEach(arrVal => {
              let calVal = {}
              if (arrayItem.type === 'multi-select') {
                calVal = arrayItem.extraOptions.find(o => o.key === arrVal)
              } else {
                calVal = this.requiredOptions.find(o => o.value === arrVal)
              }
              title = `${title}${arrayItem.label} ${calVal.value} `
            })
          } else {
            // eslint-disable-next-line no-lonely-if
            if (arrayItem.extraOptions.length) {
              const calVal = arrayItem.extraOptions.find(o => o.key === val)
              if (calVal) {
                title = `${title}${arrayItem.label} ${calVal.value} `
              } else {
                title = `${title}${arrayItem.label} ${val} `
              }
            } else {
              title = `${title}${arrayItem.label} ${val} `
            }
          }
        }
      })
      eventLocal.value.title = title
    }

    function makeCheckboxToRadio(key) {
      const lastSelected = eventLocal.value.customFields[key].value.slice(-1)[0]
      if (lastSelected) {
        eventLocal.value.customFields[key].value = eventLocal.value.customFields[key].value.filter(code => code === lastSelected)
      }

      generateBookingTitle()
    }

    function validateEmail(inputText) {
      // eslint-disable-next-line no-useless-escape
      const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
      if (inputText.match(mailformat)) {
        return true
      }
      return false
    }

    function checkForCustomerSchedule() {
      doestimePassed()
      if (eventLocal.value.startDate && (eventLocal.value.customerContact.length === 8 || validateEmail(eventLocal.value.customerEmail))) {
        let customer = ''
        if (eventLocal.value.customerContact.length === 8) {
          customer = eventLocal.value.customerContact
        } else {
          customer = eventLocal.value.customerEmail
        }
        this.$http
          .post('appointment/bookings/customer-schedule-availability', { date: eventLocal.value.startDate, time: eventLocal.value.startTime, customer })
          .then(response => {
            if (response.data.exists === true) {
              existedBooking.value = response.data.booking
              scheduleValidation.value = true
            } else {
              scheduleValidation.value = false
            }
          })
      }
    }

    function setNatureOfCalls() {
      // eslint-disable-next-line no-unused-expressions
      // this.typeOfCallValidation === true ? this.typeOfCallValidation = false : null

      this.callNatures = []

      const log = this.callLogTypes.find(o => o._id === eventLocal.value.typeOfCall)
      if (log) {
        log.callNatures.forEach(element => {
          const obj = {}
          obj.title = element
          obj.code = element
          this.callNatures.push(obj)
        })

        // this.natureOfCall = this.callNatures[0].code ?? ''
      }
    }

    function updatedActivityType() {
      const activities = store.state.calendar.calendarOptions
      eventLocal.value.title = ''

      const obj = activities.find(o => o._id === eventLocal.value.activityType)
      if (obj) {
        // this.customFields = obj.customFields
        if (!obj.customFields.length) {
          eventLocal.value.customFields = []
        } else {
          const newArray = []
          obj.customFields.forEach(arrayItem => {
            const newObj = {}
            newObj.key = arrayItem.label
            if (arrayItem.type === 'multi-select' || arrayItem.type === 'single-select') {
              newObj.value = []
            } else {
              newObj.value = ''
            }
            newArray.push(newObj)
          })

          eventLocal.value.customFields = newArray
        }
        this.$emit('update:custom-fields', obj.customFields)
      }
      getTimeFromDB()
    }

    return {
      // Add New Event
      eventLocal,
      calendarOptions,
      onSubmit,
      guestsOptions,
      statusOptions,
      sourceOfBookingOptions,
      natureOfCallOptions,
      typeOfCallOptions,
      showDescriptionField,
      showpaymentRemarksField,
      showRemarksField,
      showCallLogFields,
      showAgentFields,
      timePassedErrorMessage,
      timePassedError,

      // Form Validation
      resetForm,
      refFormObserver,
      getValidationState,
      dateInvalidFeedback,
      dateInvalidMessage,
      hoursInterval,
      existedBooking,
      scheduleValidation,
      validateEmail,
      doestimePassed,

      // methods
      updatedActivityType,
      getTimeFromDB,
      setNatureOfCalls,
      checkForCustomerSchedule,
      generateBookingTitle,
      makeCheckboxToRadio,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
